"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  CouponReference: true,
  Coupon: true,
  GroupReference: true,
  Group: true,
  GroupActivityReference: true,
  GroupActivity: true,
  AddressForm: true,
  BrazilianState: true,
  Address: true,
  EmptyAddress: true,
  AddressType: true,
  ChatApp: true,
  Country: true,
  ExportCreate: true,
  Media: true,
  Payer: true,
  Plan: true,
  PlanIntervalDescription: true,
  PlanCreate: true,
  PlanReference: true,
  Post: true,
  PostCreate: true,
  PostDelete: true,
  PostGet: true,
  PostGetAll: true,
  PostGetAllBFF: true,
  PostReference: true,
  PostReorderParam: true,
  PostSignVideo: true,
  PostUpdate: true,
  PostUploadVideo: true,
  PostGetVideoProgressBar: true,
  PostSetVideoProgressBar: true,
  PostReorder: true,
  VideoAsset: true,
  IPostListResponse: true,
  SendReferralEmail: true,
  CreateReferralForDashboard: true,
  Response: true,
  Subscription: true,
  TransactionItem: true,
  WhatsAppTemplate: true,
  MaterialReference: true,
  NotificationReference: true,
  Notification: true,
  ReminderRequestChatVerification: true,
  ReminderType: true,
  TaskReference: true,
  UserReference: true,
  User: true,
  Sellable: true,
  Bot: true,
  SellableItem: true,
  Payments: true,
  HubspotFunnelStage: true,
  HubspotContactProperties: true,
  HubspotUseCase: true,
  HubspotSalesSource: true,
  InstagramResponse: true,
  RoutesData: true,
  Trackers: true,
  Admin: true,
  Typeform: true,
  ReferralFinancial: true,
  Video: true,
  Auth: true,
  BotAction: true,
  Refund: true,
  UnknownMember: true,
  Gateway: true,
  Withdraw: true,
  Balance: true,
  GroupResource: true,
  Resource: true,
  Next: true,
  UserInfo: true,
  File: true,
  Document: true,
  Affiliate: true,
  Transaction: true,
  Waitlist: true,
  Product: true,
  GroupMember: true,
  Receiver: true,
  FeatureFlag: true,
  PostNotification: true,
  GroupNotification: true,
  Invoice: true,
  CommunicationScheduler: true,
  UserBot: true
};
Object.defineProperty(exports, "Address", {
  enumerable: true,
  get: function get() {
    return _IAddress["default"];
  }
});
Object.defineProperty(exports, "AddressForm", {
  enumerable: true,
  get: function get() {
    return _IAddress.AddressForm;
  }
});
Object.defineProperty(exports, "AddressType", {
  enumerable: true,
  get: function get() {
    return _IAddress.Type;
  }
});
exports.BotAction = exports.Bot = exports.Balance = exports.Auth = exports.Affiliate = exports.Admin = void 0;
Object.defineProperty(exports, "BrazilianState", {
  enumerable: true,
  get: function get() {
    return _IAddress.BrazilianState;
  }
});
Object.defineProperty(exports, "ChatApp", {
  enumerable: true,
  get: function get() {
    return _IChatApp.ChatApp;
  }
});
exports.CommunicationScheduler = void 0;
Object.defineProperty(exports, "Country", {
  enumerable: true,
  get: function get() {
    return _ICountry["default"];
  }
});
exports.Coupon = void 0;
Object.defineProperty(exports, "CouponReference", {
  enumerable: true,
  get: function get() {
    return _Coupon.IReference;
  }
});
Object.defineProperty(exports, "CreateReferralForDashboard", {
  enumerable: true,
  get: function get() {
    return _IReferral.CreateReferralForDashboard;
  }
});
exports.Document = void 0;
Object.defineProperty(exports, "EmptyAddress", {
  enumerable: true,
  get: function get() {
    return _IAddress.Empty;
  }
});
Object.defineProperty(exports, "ExportCreate", {
  enumerable: true,
  get: function get() {
    return _IExportRequest.ExportCreate;
  }
});
exports.GroupActivity = exports.Group = exports.Gateway = exports.File = exports.FeatureFlag = void 0;
Object.defineProperty(exports, "GroupActivityReference", {
  enumerable: true,
  get: function get() {
    return _GroupActivity.IReference;
  }
});
exports.GroupNotification = exports.GroupMember = void 0;
Object.defineProperty(exports, "GroupReference", {
  enumerable: true,
  get: function get() {
    return _Group.IReference;
  }
});
exports.GroupResource = void 0;
Object.defineProperty(exports, "HubspotContactProperties", {
  enumerable: true,
  get: function get() {
    return _IHubspot.HubspotContactProperties;
  }
});
Object.defineProperty(exports, "HubspotFunnelStage", {
  enumerable: true,
  get: function get() {
    return _IHubspot.HubspotFunnelStage;
  }
});
Object.defineProperty(exports, "HubspotSalesSource", {
  enumerable: true,
  get: function get() {
    return _IHubspot.HubspotSalesSource;
  }
});
Object.defineProperty(exports, "HubspotUseCase", {
  enumerable: true,
  get: function get() {
    return _IHubspot.HubspotUseCase;
  }
});
Object.defineProperty(exports, "IPostListResponse", {
  enumerable: true,
  get: function get() {
    return _IPost.IListResponse;
  }
});
Object.defineProperty(exports, "InstagramResponse", {
  enumerable: true,
  get: function get() {
    return _IInstagram.InstagramResponse;
  }
});
exports.Invoice = void 0;
Object.defineProperty(exports, "MaterialReference", {
  enumerable: true,
  get: function get() {
    return _Material.IReference;
  }
});
Object.defineProperty(exports, "Media", {
  enumerable: true,
  get: function get() {
    return _IMedia["default"];
  }
});
exports.Notification = exports.Next = void 0;
Object.defineProperty(exports, "NotificationReference", {
  enumerable: true,
  get: function get() {
    return _Notification.IReference;
  }
});
Object.defineProperty(exports, "Payer", {
  enumerable: true,
  get: function get() {
    return _IPayer["default"];
  }
});
exports.Payments = void 0;
Object.defineProperty(exports, "Plan", {
  enumerable: true,
  get: function get() {
    return _IPlan["default"];
  }
});
Object.defineProperty(exports, "PlanCreate", {
  enumerable: true,
  get: function get() {
    return _IPlan.IPlanCreate;
  }
});
Object.defineProperty(exports, "PlanIntervalDescription", {
  enumerable: true,
  get: function get() {
    return _IPlan.IIntervalDescription;
  }
});
Object.defineProperty(exports, "PlanReference", {
  enumerable: true,
  get: function get() {
    return _IPlan.IReference;
  }
});
Object.defineProperty(exports, "Post", {
  enumerable: true,
  get: function get() {
    return _IPost["default"];
  }
});
Object.defineProperty(exports, "PostCreate", {
  enumerable: true,
  get: function get() {
    return _IPost.IPostCreate;
  }
});
Object.defineProperty(exports, "PostDelete", {
  enumerable: true,
  get: function get() {
    return _IPost.IPostDelete;
  }
});
Object.defineProperty(exports, "PostGet", {
  enumerable: true,
  get: function get() {
    return _IPost.IPostGet;
  }
});
Object.defineProperty(exports, "PostGetAll", {
  enumerable: true,
  get: function get() {
    return _IPost.IPostGetAll;
  }
});
Object.defineProperty(exports, "PostGetAllBFF", {
  enumerable: true,
  get: function get() {
    return _IPost.IPostGetAllBFF;
  }
});
Object.defineProperty(exports, "PostGetVideoProgressBar", {
  enumerable: true,
  get: function get() {
    return _IPost.IPostGetVideoProgressBar;
  }
});
exports.PostNotification = void 0;
Object.defineProperty(exports, "PostReference", {
  enumerable: true,
  get: function get() {
    return _IPost.IPostReference;
  }
});
Object.defineProperty(exports, "PostReorder", {
  enumerable: true,
  get: function get() {
    return _IPost.PostReorder;
  }
});
Object.defineProperty(exports, "PostReorderParam", {
  enumerable: true,
  get: function get() {
    return _IPost.IPostReorderParam;
  }
});
Object.defineProperty(exports, "PostSetVideoProgressBar", {
  enumerable: true,
  get: function get() {
    return _IPost.IPostSetVideoProgressBar;
  }
});
Object.defineProperty(exports, "PostSignVideo", {
  enumerable: true,
  get: function get() {
    return _IPost.IPostSignVideo;
  }
});
Object.defineProperty(exports, "PostUpdate", {
  enumerable: true,
  get: function get() {
    return _IPost.IPostUpdate;
  }
});
Object.defineProperty(exports, "PostUploadVideo", {
  enumerable: true,
  get: function get() {
    return _IPost.IPostUploadVideo;
  }
});
exports.Refund = exports.ReferralFinancial = exports.Receiver = exports.Product = void 0;
Object.defineProperty(exports, "ReminderRequestChatVerification", {
  enumerable: true,
  get: function get() {
    return _Reminder.ReminderRequestChatVerification;
  }
});
Object.defineProperty(exports, "ReminderType", {
  enumerable: true,
  get: function get() {
    return _Reminder.ReminderType;
  }
});
exports.Response = exports.Resource = void 0;
Object.defineProperty(exports, "RoutesData", {
  enumerable: true,
  get: function get() {
    return _Route.RoutesData;
  }
});
exports.SellableItem = exports.Sellable = void 0;
Object.defineProperty(exports, "SendReferralEmail", {
  enumerable: true,
  get: function get() {
    return _IReferral.SendReferralEmail;
  }
});
exports.Subscription = void 0;
Object.defineProperty(exports, "TaskReference", {
  enumerable: true,
  get: function get() {
    return _Task.IReference;
  }
});
exports.Transaction = exports.Trackers = void 0;
Object.defineProperty(exports, "TransactionItem", {
  enumerable: true,
  get: function get() {
    return _IItem.IItem;
  }
});
exports.UserInfo = exports.UserBot = exports.User = exports.UnknownMember = exports.Typeform = void 0;
Object.defineProperty(exports, "UserReference", {
  enumerable: true,
  get: function get() {
    return _User.IReference;
  }
});
exports.Video = void 0;
Object.defineProperty(exports, "VideoAsset", {
  enumerable: true,
  get: function get() {
    return _IPost.VideoAsset;
  }
});
exports.Waitlist = void 0;
Object.defineProperty(exports, "WhatsAppTemplate", {
  enumerable: true,
  get: function get() {
    return _IWhatsAppTemplate.IWhatsAppTemplate;
  }
});
exports.Withdraw = void 0;

var _Coupon = _interopRequireWildcard(require("./Coupon"));

exports.Coupon = _Coupon;

var _Group = _interopRequireWildcard(require("./Group"));

exports.Group = _Group;

var _GroupActivity = _interopRequireWildcard(require("./GroupActivity"));

exports.GroupActivity = _GroupActivity;

var _IAddress = _interopRequireWildcard(require("./IAddress"));

var _IChatApp = require("./IChatApp");

var _ICountry = _interopRequireDefault(require("./ICountry"));

var _IExportRequest = require("./IExportRequest");

var _IMedia = _interopRequireDefault(require("./IMedia"));

var _IPayer = _interopRequireDefault(require("./IPayer"));

var _IPlan = _interopRequireWildcard(require("./IPlan"));

var _IPost = _interopRequireWildcard(require("./IPost"));

var _IReferral = require("./IReferral");

var Response = _interopRequireWildcard(require("./IResponse"));

exports.Response = Response;

var Subscription = _interopRequireWildcard(require("./Subscription"));

exports.Subscription = Subscription;

var _IItem = require("./Transaction/IItem");

var _IWhatsAppTemplate = require("./IWhatsAppTemplate");

var _Material = require("./Material");

var _Notification = _interopRequireWildcard(require("./Notification"));

exports.Notification = _Notification;

var _Reminder = require("./Reminder");

var _Task = require("./Task");

var _User = _interopRequireWildcard(require("./User"));

exports.User = _User;

var _Sellable = _interopRequireWildcard(require("./ISellable"));

exports.Sellable = _Sellable;

var _Bot = _interopRequireWildcard(require("./IBot"));

exports.Bot = _Bot;

var _SellableItem = _interopRequireWildcard(require("./ISellableItem"));

exports.SellableItem = _SellableItem;

var _Payments = _interopRequireWildcard(require("./Payments"));

exports.Payments = _Payments;

var _IHubspot = require("./IHubspot");

var _IInstagram = require("./IInstagram");

var _Route = require("./Route");

var _Trackers = _interopRequireWildcard(require("./ITrackers"));

exports.Trackers = _Trackers;

var _Admin = _interopRequireWildcard(require("./Admin"));

exports.Admin = _Admin;

var _Typeform = _interopRequireWildcard(require("./Typeform"));

exports.Typeform = _Typeform;

var _ReferralFinancial = _interopRequireWildcard(require("./ReferralFinancial"));

exports.ReferralFinancial = _ReferralFinancial;

var _Video = _interopRequireWildcard(require("./Video"));

exports.Video = _Video;

var _Auth = _interopRequireWildcard(require("./IAuth"));

exports.Auth = _Auth;

var _BotAction = _interopRequireWildcard(require("./BotAction"));

exports.BotAction = _BotAction;

var _Refund = _interopRequireWildcard(require("./Refund"));

exports.Refund = _Refund;

var _UnknownMember = _interopRequireWildcard(require("./UnknownMember"));

exports.UnknownMember = _UnknownMember;

var _Gateway = _interopRequireWildcard(require("./Gateway"));

exports.Gateway = _Gateway;

var _Withdraw = _interopRequireWildcard(require("./Withdraw"));

exports.Withdraw = _Withdraw;

var _Balance = _interopRequireWildcard(require("./IBalance"));

exports.Balance = _Balance;

var _GroupResource = _interopRequireWildcard(require("./GroupResource"));

exports.GroupResource = _GroupResource;

var _Resource = _interopRequireWildcard(require("./Resource"));

exports.Resource = _Resource;

var _Next = _interopRequireWildcard(require("./Next"));

exports.Next = _Next;

var _UserInfo = _interopRequireWildcard(require("./UserInfo"));

exports.UserInfo = _UserInfo;

var _File = _interopRequireWildcard(require("./IFile"));

exports.File = _File;

var _Document = _interopRequireWildcard(require("./IDocument"));

exports.Document = _Document;

var _Affiliate = _interopRequireWildcard(require("./Affiliate"));

exports.Affiliate = _Affiliate;

var _Transaction = _interopRequireWildcard(require("./Transaction"));

exports.Transaction = _Transaction;

var _Waitlist = _interopRequireWildcard(require("./Waitlist"));

exports.Waitlist = _Waitlist;

var _Product = _interopRequireWildcard(require("./Product"));

exports.Product = _Product;

var _GroupMember = _interopRequireWildcard(require("./GroupMember"));

exports.GroupMember = _GroupMember;

var _IIdentifier = require("./IIdentifier");

Object.keys(_IIdentifier).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _IIdentifier[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _IIdentifier[key];
    }
  });
});

var _TaxInvoice = require("./TaxInvoice");

Object.keys(_TaxInvoice).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _TaxInvoice[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _TaxInvoice[key];
    }
  });
});

var _TelegramUpdateEvents = require("./TelegramUpdateEvents");

Object.keys(_TelegramUpdateEvents).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _TelegramUpdateEvents[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _TelegramUpdateEvents[key];
    }
  });
});

var _Receiver = _interopRequireWildcard(require("./Receiver"));

exports.Receiver = _Receiver;

var _FeatureFlag = _interopRequireWildcard(require("./FeatureFlag"));

exports.FeatureFlag = _FeatureFlag;

var _PostNotification = _interopRequireWildcard(require("./PostNotification"));

exports.PostNotification = _PostNotification;

var _GroupNotification = _interopRequireWildcard(require("./GroupNotification"));

exports.GroupNotification = _GroupNotification;

var _Invoice = _interopRequireWildcard(require("./Invoice"));

exports.Invoice = _Invoice;

var _CommunicationScheduler = _interopRequireWildcard(require("./CommunicationScheduler"));

exports.CommunicationScheduler = _CommunicationScheduler;

var _UserBot = _interopRequireWildcard(require("./UserBot"));

exports.UserBot = _UserBot;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }